import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import * as Styled from "../css/CardFlexStyle"
import getLang from '../components/scripts/getLang'
import CheckForHighlight from "../components/scripts/checkForHightlight";

import { LokalerQuery } from './scripts/graphQlQuerys/lokalerQuerys'

export const LokalGraphql = ({ SearchKeyword, english, pageType }) => {
  if (SearchKeyword === undefined) {
    SearchKeyword = ""
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi")
  let lokal = getLang(english, LokalerQuery())
  lokal = CheckForHighlight(pageType, lokal)
    .filter(edge => {
      let newString = edge.node.skord.toString()
      return newString.match(regex)
    })
    .map((item, index) => {
      let orderOne = "";
      if(item.node.highlight){
        orderOne = "orderOne"
      }
      return (
        <Link
          className={"textDecorationNone " + orderOne}
          key={index}
          to={`/event/venues/${item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={item.node.karusellBilder[0].fluid}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
              <p>{item.node.huvudbyggnad.toUpperCase()}</p>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      )
    })

  return (
    <Styled.CenterDiv>
      <Styled.FlexContainer>{lokal}</Styled.FlexContainer>
    </Styled.CenterDiv>
  )
}
