import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import { LokalGraphql } from "../../components/lokalerSearch";
import EventPuff from "../../components/eventPuff";
import App from "../app";
import getLang from "../../components/scripts/getLang";
import Taggar from "../../components/taggar";
import Searchbar from "../../components/searchbar";
import Hero from '../../components/hero'
import * as Global from "../../css/globalStyle";

import { EventQuery } from "../../components/scripts/graphQlQuerys/eventQuery";

const Lokal = () => {
  let [english] = useGlobal("English");
  const pageType = "alla";
  const [SearchKeyword, setSearchKeyword] = useState(" ");
  let newData = getLang(english, EventQuery().allContentfulEventSida);
  let { introText, seoDescription, seoTitel, Synligataggar } = newData[0].node;

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "NewYear", newURL);
    }
  });

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero introText={introText} heroTema={null} data={newData} img={'img1'} />
      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword} />
      <Taggar mainPage="lokal" subPage="Alla" data={Synligataggar} />
      <LokalGraphql english={english} SearchKeyword={SearchKeyword} pageType={pageType}/>
      <EventPuff english={english} />
    </App>
  );
};

export default Lokal;
