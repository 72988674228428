import React from "react";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import * as Styled from "../css/CardFlexStyle";
import getLang from "./scripts/getLang";

import { EventQuery } from "../components/scripts/graphQlQuerys/eventQuery";

const EventPuff = ({ english }) => {
  let Events = getLang(english, EventQuery().allContentfulEventEnheter).map(
    (item, index) => {
      return (
        <Link
          key={index}
          className={"textDecorationNone extraLarge orderOne "}
          to={`/event/${item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={item.node.huvudBild.fluid}
            key={index}
          >
            <Styled.FlexH1>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      );
    }
  );

  return (
    <Styled.Container>
      <Styled.FlexContainer>{Events}</Styled.FlexContainer>
    </Styled.Container>
  );
};

export default EventPuff;
