import { graphql, useStaticQuery } from "gatsby";

export const LokalerQuery = () => {
  let query = useStaticQuery(
    graphql`
    query {
      allContentfulLokaler(sort: { fields: [namn], order: ASC }) {
        edges {
          node {
            updatedAt(formatString: "YY-MM/DD  h:mm:ss")
            node_locale
            heroLokal
            namn
            huvudbyggnad
            skord
            highlightadPSida
            slug
            huvudbild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            karusellBilder {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    `
  );

  return query.allContentfulLokaler
};
